
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { Validation } from '@/ui/mixins/validation';

@Component({
  components: {
    ModalWindow,
  },
})
export default class DaySelection extends mixins(Validation) {
  @Prop({ default: undefined }) date!: string;
  @Prop({ default: 'YYYY-MM-DD' }) dateFormat!: string;
  @Prop({ default: 'Select Date' }) buttonText!: string;
  @Prop({ default: 'dateSelected' }) emitName!: string;
  @Prop({ default: false }) icon!: boolean;

  selectedDate = '';

  emitDate() {
    this.$emit(this.emitName, this.selectedDate);

    this.resetVariable();
    (this.$refs.ModalWindowInstance as ModalWindow).onConfirmationDialogClose();
  }

  async onDialogStatusChange(status: boolean) {
    if (!status) {
      this.resetVariable();
    }
  }

  resetVariable() {
    // reset value
    this.selectedDate = '';
  }

  created() {
    if (this.date) {
      this.selectedDate = this.date;
    }
  }
}


import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class LeadsFilterOptions extends Vue {
  @Prop({ default: false }) showAsPartnerList!: boolean;
  @Prop({ default: 'lead' }) currentSelectedFilter!: string;

  get filterOptions() {
    return [
      {
        value: 'lead',
        text: this.$t('partners.partnerManagement.leads.tableHeaders.lead'),
      },
      ...(!this.showAsPartnerList ? [{
        value: 'partner_id',
        text: this.$t('partners.partnerManagement.leads.tableHeaders.partner'),
      }] : []),
      {
        value: 'state',
        text: this.$t('partners.partnerManagement.leads.tableHeaders.state'),
      },
    ];
  }
  selectedFilter = '';

  mounted() {
    // set filter if some is already selected
    this.selectedFilter = this.currentSelectedFilter;
  }

  menu = false;
}

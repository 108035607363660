
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { Validation } from '@/ui/mixins/validation';
import { IPartner, IPartnerData } from '@/types/partner.types';
import { Action } from 'vuex-class';

@Component({
  components: {
    ModalWindow,
  },
})
export default class CreateProject extends mixins(Validation) {
  @Prop({ default: false }) handleAsEdit!: boolean;
  @Prop({ default: null }) partnerObject!: IPartner | null;
  @Action('partners/createPartner') createPartner!: (partner: IPartnerData) => Promise<void>;
  @Action('partners/updatePartner') updatePartner!: (data: { id: string; partner: IPartner; showMessage: boolean }) => Promise<void>;

  valid = false;
  partner: IPartnerData = {
    name: '',
    address: '',
    contact_person: '',
    phone_number: null,
    state: 'ok',
    info: {
      email: '',
      website: '',
      text: '',
    },
  }

  get stateItems() {
    return [
      { text: 'ok', value: 'ok' },
      { text: 'bearbeitet', value: 'bearbeitet' },
      { text: 'negativ', value: 'negativ' },
    ];
  }

  get currentLang() {
    return this.$i18n.locale;
  }

  async onDialogStatusChange(status: boolean) {
    if (status) {
      if (this.handleAsEdit && this.partnerObject) {
        this.partner = { ...this.partnerObject.data };
      }
      await this.$nextTick();
      this.validate();
    }
  }

  @Watch('currentLang', { deep: true })
  handleLangChange() {
    this.validate();
  }

  validate() {
    (this.$refs.form as any)?.validate();
  }

  async addPartner() {
    if (this.handleAsEdit === false) {
      // create new partner
      this.createPartner(this.partner);
    } else {
      if (!this.partnerObject) return;
      const finalObject: IPartner = { ...this.partnerObject };
      finalObject.data = this.partner;
      // update current partner
      const currentId: any = this.partnerObject?.id;
      this.updatePartner({ id: currentId, partner: finalObject, showMessage: false });
    }
    (this.$refs.ModalWindowInstance as ModalWindow).onConfirmationDialogClose();
  }
}

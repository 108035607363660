
import { Vue, Component, Prop } from 'vue-property-decorator';
import { IPartner } from '@/types/partner.types';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import LeadsForPartner from '@/ui/components/partner/management/settings/LeadsForPartner.vue';
import UserManagement from '@/ui/components/partner/management/settings/UserManagement.vue';
import GeneralSettings from '@/ui/components/partner/management/settings/GeneralSettings.vue';
import { IAppState } from '@/store/modules/app/types';
import { State } from 'vuex-class';
import { UserRoleCode } from '@/utils/userRoles';

@Component({
  components: {
    LeadsForPartner,
    UserManagement,
    ModalWindow,
    GeneralSettings,
  },
})
export default class PartnerSettings extends Vue {
  @Prop({ default: {} }) partnerObject!: IPartner;
  @State('app') appState!: IAppState;

  showPopup = false;
  tab = 0;

  get currentUserAdmin() {
    if (this.appState.user.super_admin) return true;

    if (this.partnerObject.users) {
      const member = this.partnerObject.users.find((member) => member.id === this.appState.user.id);
      if (member) {
        return member.role === UserRoleCode.admin;
      }
    }

    return false;
  }

  get tabs() {
    return [
      {
        name: 'Leads',
        locale: this.$t('partners.partnerManagement.partner.settings.tabs.leads'),
        component: 'LeadsForPartner',
      },
      ...(this.currentUserAdmin ? [{
        name: 'User Management',
        locale: this.$t('partners.partnerManagement.partner.settings.tabs.userManagement'),
        component: 'UserManagement',
      }] : []),
      ...(this.currentUserAdmin ? [{
        name: 'General Settings',
        locale: this.$t('partners.partnerManagement.partner.settings.tabs.generalSettings'),
        component: 'GeneralSettings',
      }] : []),
    ];
  }

  handleClose() {
    this.showPopup = false;
  }
}


import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { IPartner, IPartnerData } from '@/types/partner.types';
import { Action, Mutation, State } from 'vuex-class';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import { IAppState } from '@/store/modules/app/types';

@Component({
  components: {
    DeleteModalForm,
  },
})
export default class GeneralSettings extends mixins(Validation) {
  @State('app') appState!: IAppState;
  @Prop({ default: null }) partnerObject!: IPartner | null;
  @Mutation('app/setReport') setReport!: (payload: any) => void;
  @Action('partners/createPartner') createPartner!: (partner: IPartnerData) => Promise<void>;
  @Action('partners/updatePartner') updatePartner!: (data: { id: string; partner: Partial<IPartner>; showMessage: boolean}) => Promise<void>;
  @Action('partners/deletePartner') deletePartner!: (id: string) => Promise<void>;

  valid = false;
  partner: IPartnerData = {
    name: '',
    address: '',
    contact_person: '',
    phone_number: null,
    state: 'ok',
    info: {
      email: '',
      website: '',
      text: '',
    },
  }

  get stateItems() {
    return [
      {
        text: this.$t('partners.partnerManagement.partner.settings.stateItems.ok'),
        value: 'ok',
      },
      {
        text: this.$t('partners.partnerManagement.partner.settings.stateItems.processed'),
        value: 'bearbeitet',
      },
      {
        text: this.$t('partners.partnerManagement.partner.settings.stateItems.negative'),
        value: 'negativ',
      },
    ];
  }

  get currentLang() {
    return this.$i18n.locale;
  }

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  @Watch('currentLang', { deep: true })
  handleLangChange() {
    this.validate();
  }

  deleteProject() {
    this.deletePartner(this.partnerObject?.id as string);
  }

  validate() {
    (this.$refs.form as any)?.validate();
  }

  async update() {
    if (!this.partnerObject) return;
    const finalObject: IPartner = { ...this.partnerObject };
    finalObject.data = this.partner;
    // update current partner
    const currentId: string = this.partnerObject?.id ?? '';
    try {
      await this.updatePartner({ id: currentId, partner: { data: this.partner }, showMessage: false });
      this.showUpdateMessage();
    } catch (e) {
      this.setReport({
        type: 'error',
        message: e.message,
        value: true,
      });
    }
  }

  showUpdateMessage() {
    this.setReport({
      type: 'success',
      message: this.$t('uiComponents.reportMessages.updatePartner'),
      value: true,
    });
  }

  created() {
    if (this.partnerObject) {
      this.partner = { ...this.partnerObject.data };
    }
  }
}

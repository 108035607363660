
import { Vue, Component, Prop } from 'vue-property-decorator';
import LynusPopup from '@/ui/components/components/LynusPopup.vue';
import { IPartner } from '@/types/partner.types';
import { ILead } from '@/types/leads.types';
import LeadsList from '@/ui/components/partner/management/lists/LeadsList.vue';
import { Getter } from 'vuex-class';

@Component({
  components: {
    LynusPopup,
    LeadsList,
  },
})
export default class LeadsForPartner extends Vue {
  @Prop({ default: {} }) partnerObject!: IPartner;
  @Getter('leads/leads') leads!: ILead[];

  /**
   * Returns a list of leads that are assigned to the current partner
   */
  get leadsForPartner() {
    return this.leads.filter((lead: ILead) => lead.partner_id === this.partnerObject.id);
  }
}

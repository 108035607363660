
import { Vue, Component } from 'vue-property-decorator';
import PartnerSettings from '@/ui/components/partner/management/settings/PartnerSettings.vue';
import ManagePartner from '@/ui/components/modals/Partner/ManagePartner.vue';
import { IPartner } from '@/types/partner.types';
import { IAppState } from '@/store/modules/app/types';
import { Getter, State } from 'vuex-class';

@Component({
  components: {
    PartnerSettings,
    ManagePartner,
  },
})
export default class PartnerManagement extends Vue {
  @State('app') appState!: IAppState;
  @Getter('partners/partners') partners!: IPartner[];

  search = '';

  get filteredPartners() {
    if (this.search === '') {
      return this.partners;
    }
    return this.partners.filter((partner) => {
      return partner.data.name.toLowerCase().includes(this.search.toLowerCase());
    });
  }

  get partnersData() {
    return this.partners.map((partner) => {
      return partner.data;
    });
  }

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  get headers() {
    return [
      {
        text: this.$t('partners.partnerManagement.partner.tableHeaders.partnerName'),
        value: 'name',
        sortable: false,
        width: '15%',
      },
      {
        text: this.$t('partners.partnerManagement.partner.tableHeaders.address'),
        value: 'address',
        sortable: false,
        width: '20%',
      },
      {
        text: this.$t('partners.partnerManagement.partner.tableHeaders.contactPerson'),
        value: 'contact_person',
        sortable: false,
        width: '15%',
      },
      {
        text: this.$t('partners.partnerManagement.partner.tableHeaders.phoneNumber'),
        value: 'phone_number',
        sortable: false,
        width: '15%',
      },
      {
        text: this.$t('partners.partnerManagement.partner.tableHeaders.state'),
        value: 'state',
        sortable: false,
        width: '10%',
      },
      {
        text: this.$t('partners.partnerManagement.partner.tableHeaders.actions'),
        value: 'info',
        sortable: false,
        width: '15%',
      },
    ];
  }
}

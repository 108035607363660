
import { ILead } from '@/types/leads.types';
import { Vue, Component } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { IAppState } from '@/store/modules/app/types';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import DuplicationStatusIndicator from '@/ui/components/components/DuplicationStatusIndicator.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';

@Component({
  components: {
    ModalWindow,
    DuplicationStatusIndicator,
    CircleSpinner,
  },
})
export default class UploadLeads extends Vue {
  @State('app') appState!: IAppState;
  @Action('leads/createLead') createLead!: (lead: ILead) => Promise<void>;

  async onDialogStatusChange(status: boolean) {
    if (!status) {
      this.fileObject = [];
      this.uploadStatus = [];
      this.showSummary = false;
      this.showLoader = false;
    }
  }

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  get partnerId() {
    return this.appState.user.partner ?? '';
  }

  fileObject: any = [];
  uploadStatus: any = [];
  fileObjectCopy: any = [];
  showSummary = false;
  showLoader = false;

  checkIsUploaded(item: any) {
    const res = this.uploadStatus.find((statusItem: any) => statusItem.filename === item.name);
    if (!res) {
      return true;
    }
    return false;
  }

  async handleFileSelect() {
    const files = this.fileObject as File[];
    this.fileObjectCopy = [...files];
    this.uploadStatus = [];
    this.showSummary = false;
    this.showLoader = true;

    const readFilesPromises = files.map(file => {
      if (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel') {
      // Only process csv files to avoid errors
        return this.readFile(file);
      }
      return Promise.resolve(); // Return a resolved promise for non-csv files
    });

    // Wait for all readFile promises to complete
    let result: any = await Promise.all(readFilesPromises);
    // Filter out undefined results
    result = result.filter((item: { newLead: ILead; filename: string} | undefined) => item !== undefined);
    // generate each lead with a delay of 2 seconds
    await result.forEach(async (item: { newLead: ILead; filename: string}, index: number) => {
      await setTimeout(async () => {
        await this.createLead(item.newLead);
        this.uploadStatus.push({ filename: item.filename, status: 'success' });
        if (index === result.length - 1) {
          // enable the summary after the last file has been processed
          this.showSummary = true;
          this.showLoader = false;
        }
      }, index * 2000);
    });

    // If no files were processed, hide the loader and show the summary
    if (result.length === 0) {
      this.showLoader = false;
      this.showSummary = true;
    }
    this.fileObject = [];
  }

  readFile(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const text = e.target.result;
        const lines = text.split('\n');
        const newLead = this.convertCSVToLead(lines);
        resolve({ newLead, filename: file.name }); // Resolve the promise when reading is complete
      };
      reader.onerror = reject; // Reject the promise on error
      reader.readAsText(file);
    });
  }

  convertCSVToLead(lines: string[]) {
    // split the lines by the delimiter so we have all the values in an array
    const splitLines = lines.map((line) => line.split(';'));
    const headers = splitLines[0];
    const values = splitLines[1];
    if (!headers || !values) {
      // if headers or values are not present, return
      // we only want to process files with headers and values
      return;
    }
    const defaultLeadObject: ILead = {
      lead: '',
      address: '',
      volume: 0,
      currency: '',
      partner_id: this.partnerId,
      state: 'notContacted',
      created_at: '',
      phone_number: '',
      solarcalculator_id: '',
      id: '',
      email: '',
      notes: [],
    };

    defaultLeadObject.email = this.valueOfHeader(headers, 'email', values);
    defaultLeadObject.phone_number = this.valueOfHeader(headers, 'phone', values);
    defaultLeadObject.lead = `${this.valueOfHeader(headers, 'first_name', values)} ${this.valueOfHeader(headers, 'last_name', values)}`;
    defaultLeadObject.address = `${this.valueOfHeader(headers, 'postcode', values)}, ${this.valueOfHeader(headers, 'housenumber', values)} ${this.valueOfHeader(headers, 'street', values)}, ${this.valueOfHeader(headers, 'city', values)}, ${this.valueOfHeader(headers, 'country', values)}`;
    return defaultLeadObject;
  }

  /**
   * @param headers headers of the csv file
   * @param header the header to search for
   * @param valueLine values of the csv file
   * returns the value of the given header
   */
  valueOfHeader(headers: string[], header: string, valueLine: string[]) {
    const index = headers.indexOf(header);
    return valueLine[index];
  }
}

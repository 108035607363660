
import { IPartner } from '@/types/partner.types';
import { ILead } from '@/types/leads.types';
import { formatDate } from '@/utils/utilsFunctions';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import UploadLeads from '@/ui/components/modals/Partner/UploadLeads.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { IAppState } from '@/store/modules/app/types';
import LeadsInformation from '@/ui/components/modals/Partner/LeadsInformation.vue';
import LeadsFilterOptions from '@/ui/components/partner/components/LeadsFilterOptions.vue';
import { stateItems, nameByPartnerId } from '@/utils/partnerUtils';

@Component({
  components: {
    DeleteModalForm,
    InfoTooltip,
    LeadsInformation,
    LeadsFilterOptions,
    UploadLeads,
  },
  methods: {
    nameByPartnerId(id: string, partners: IPartner[]) {
      return nameByPartnerId(id, partners);
    },
  },
})
export default class LeadsList extends Vue {
  @State('app') appState!: IAppState;
  @Prop({ default: false }) showAsPartnerList!: boolean;
  @Prop({ default: () => [] }) leadsForPartner!: ILead[];
  @Getter('partners/partners') partners!: IPartner[];
  @Getter('leads/leads') leads!: ILead[];
  @Action('leads/updateLead') updateLead!: (data: { id: string; lead: ILead; showMessage: boolean; skipFetch: boolean }) => Promise<void>;
  @Action('leads/deleteLeads') deleteLead!: (id: string) => Promise<void>;

  search = '';
  searchBy = 'lead';

  get currentBreakpoint() {
    return this.$vuetify.breakpoint.name;
  }

  get localStateItems() {
    const items = stateItems();
    return items;
  }

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  get leadsListFiltered() {
    const searchByParamenter: keyof ILead = this.searchBy as keyof ILead;
    if (this.search === '') {
      // if no search is active, return the full list
      return this.leadsList;
    } else {
      if (this.searchBy === 'partner_id') {
        // for partner search, return the leads that match the partner and filter out the ones without a partner
        if (this.search === 'none') {
          return this.leadsList.filter((lead: ILead) => lead[searchByParamenter] === null);
        } else {
          return this.leadsList.filter((lead: ILead) => lead[searchByParamenter] === this.search);
        }
      } else if (this.searchBy === 'state' && this.search === 'none') {
        // if the search is for no state, return the leads without a state
        return this.leadsList.filter((lead: ILead) => lead[searchByParamenter] === '');
      } else {
        // return the leads that match the search
        return this.leadsList.filter((lead: ILead) => lead.state.toLowerCase().includes(this.search.toLowerCase()));
      }
    }
  }

  get leadsList() {
    let leads;
    if (this.showAsPartnerList) {
      // contains only leads where this partner is assigned
      leads = this.leadsForPartner;
    } else {
      // get full lust from backend
      leads = this.leads;
    }

    // return the leads sorted by date (newest to oldest)
    return leads.sort((a: ILead, b: ILead) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
  }

  /*
    * Returns the partner items for the partner select
    */
  get partnerItems() {
    const mappings = this.partners.map((partner) => {
      return { value: partner.id, text: partner.data.name };
    });
    return mappings;
  }

  get filterOptions() {
    let options = [];
    switch (this.searchBy) {
      case 'partner_id':
        options = this.partnerItems;
        options.push({
          value: 'none',
          text: this.$t('partners.partnerManagement.leads.noPartner')as string,
        });
        return options;
      case 'state':
        options = this.localStateItems;
        options.push({
          value: 'none',
          text: this.$t('partners.partnerManagement.leads.noState')as string,
        });
        return options;
      default:
        return undefined;
    }
  }

  getStateByValue(value: string) {
    const state = this.localStateItems.find((item) => item.value === value);
    return state?.text;
  }

  get headers() {
  // Define common header structure
    const commonHeaders = [
      {
        text: this.$t('partners.partnerManagement.leads.tableHeaders.lead'),
        value: 'lead',
        sortable: false,
        width: this.isLargeScreen ? '15%' : '20%',
      },
      {
        text: this.$t('partners.partnerManagement.leads.tableHeaders.address'),
        value: 'address',
        sortable: false,
        width: this.isLargeScreen ? '35%' : '40%',
      },
      {
        text: this.$t('partners.partnerManagement.leads.tableHeaders.date'),
        value: 'created_at',
        sortable: false,
        width: this.isLargeScreen ? '10%' : '20%',
      },
    ];

    // Append specific headers based on conditions
    if (this.isLargeScreen) {
      if (!this.showAsPartnerList) {
        commonHeaders.push({
          text: this.$t('partners.partnerManagement.leads.tableHeaders.partner'),
          value: 'partner_id',
          sortable: false,
          width: '15%',
        });
      }
      commonHeaders.push({
        text: this.$t('partners.partnerManagement.leads.tableHeaders.state'),
        value: 'state',
        sortable: false,
        width: '15%',
      });
    }

    // Actions header, common but with a conditional width
    commonHeaders.push({
      text: this.$t('partners.partnerManagement.leads.tableHeaders.actions'),
      value: 'actions',
      sortable: false,
      width: this.isLargeScreen ? '10%' : '20%',
    });

    return commonHeaders;
  }

  // determine if the current breakpoint is large
  get isLargeScreen() {
    const largeBreakpoints = ['xl', 'lg'];
    return largeBreakpoints.includes(this.currentBreakpoint);
  }

  /**
   * Opens the solar calculator with the result in a new tab
   */
  openSolarCalculator(id: string) {
    const url = `https://solarrechner.lynus.io/results/${id}`;
    window.open(url, '_blank');
  }

  /**
   * Updates lead
   */
  handlePartnerUpdate(id: string) {
    const index = this.leads.findIndex((lead) => lead.id === id);
    const updatedLead = { ...this.leads[index] };
    this.updateLead({ id, lead: updatedLead, showMessage: true, skipFetch: true });
  }

  getFormatedDate(date: string) {
    let currentDate: Date | string = new Date(date);
    currentDate = formatDate(currentDate);
    return currentDate;
  }

  handleDelete(id: string) {
    this.deleteLead(id);
  }

  handleFilterChange(selectedFilter: string) {
    this.search = '';
    this.searchBy = selectedFilter;
  }

  handleClearSearch() {
    this.search = '';
  }
}

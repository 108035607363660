import { IPartner } from '@/types/partner.types';
import i18n from '@/ui/plugins/i18n';

/**
 * @returns list of lead state items
 */
export function stateItems() {
  return [
    {
      value: 'Terminiert',
      text: i18n.t('partners.partnerManagement.leads.stateItems.terminated'),
    },
    {
      value: 'Angebot Erstellt',
      text: i18n.t('partners.partnerManagement.leads.stateItems.offerCreated'),
    },
    {
      value: 'AF Aufmasstermin erledigt',
      text: i18n.t('partners.partnerManagement.leads.stateItems.measurementDateDone'),
    },
    {
      value: 'AB Angebotsbesprechung gehabt',
      text: i18n.t('partners.partnerManagement.leads.stateItems.hadOfferDiscussion'),
    },
    {
      value: 'Follow up',
      text: i18n.t('partners.partnerManagement.leads.stateItems.followUp'),
    },
    {
      value: 'Closing Prozess',
      text: i18n.t('partners.partnerManagement.leads.stateItems.closingProcess'),
    },
    {
      value: 'Reklamation',
      text: i18n.t('partners.partnerManagement.leads.stateItems.complaint'),
    },
    {
      value: 'Abgesagt',
      text: i18n.t('partners.partnerManagement.leads.stateItems.canceled'),
    },
    {
      value: 'Abgeschlossen',
      text: i18n.t('partners.partnerManagement.leads.stateItems.completed'),
    },
    {
      value: 'notContacted',
      text: i18n.t('partners.partnerManagement.leads.stateItems.notContacted'),
    },
    {
      value: 'contactAttempted',
      text: i18n.t('partners.partnerManagement.leads.stateItems.contactAttempted'),
    },
    {
      value: 'contactSuccessful',
      text: i18n.t('partners.partnerManagement.leads.stateItems.contactSuccessful'),
    },
    {
      value: 'contactFailed',
      text: i18n.t('partners.partnerManagement.leads.stateItems.contactFailed'),
    },
  ];
}

/**
 * @param id partner id as string
 * @param partners partner list as IPartner[]
 * @returns partner name as string
*/
export function nameByPartnerId(id: string, partners: IPartner[]) {
  const partner = partners.find((partner) => partner.id === id);
  return partner?.data.name;
}


import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import LynusPopup from '@/ui/components/components/LynusPopup.vue';
import { IPartner, IPartnerMember } from '@/types/partner.types';
import UserService from '@/services/UserService';
import { IFetchPermissionUserResponse } from '@/types/common.types';
import { IMember } from '@/types/members.types';
import { UserRoleCode } from '@/utils/userRoles';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { IAppState } from '@/store/modules/app/types';
import DaySelection from '@/ui/components/partner/components/DaySelection.vue';
import { formatIsoDate } from '@/utils/utilsFunctions';

@Component({
  methods: { formatIsoDate },
  components: {
    LynusPopup,
    DaySelection,
  },
})
export default class UserManagement extends Vue {
  @State('app') appState!: IAppState;
  @Prop({ default: undefined }) partnerId!: string;
  @Getter('partners/getPartnerById') getPartnerById!: (partnerId: string) => IPartner | null;
  @Mutation('app/setReport') setReport!: (payload: any) => void;
  @Action('partners/fetchPartner') fetchPartner!: (data: { id: string }) => Promise<void>;
  @Action('partners/updatePartner') updatePartner!: (data: { id: string; partner: Partial<IPartner>; showMessage: boolean}) => void;

  // User Search
  model = null;
  search = '';
  items: IFetchPermissionUserResponse[] = [];
  loading = false;
  selectedUserObject: IMember | null = null;

  @Watch('model')
  async onSelected(val: IMember | null) {
    if (val === null) return;
    this.selectedUserObject = { ...(this.items[0] as any) };
    if (this.selectedUserObject === null) return;
    if (!this.checkIfUserExists(this.selectedUserObject.email)) {
      const newUserObject = { id: this.selectedUserObject.id, role: 1001, last_training: undefined }; // get the new user id
      const usersArray = (this.partnerObject?.users as IPartnerMember[]).map((member) => {
        return { id: member.id, role: member.role, last_training: member.last_training };
      }); // load the current users
      usersArray?.push(newUserObject); // add the new user id into the array of users
      try {
        await this.handleUpdatePartner(usersArray);
        this.showUpdateMessage();
      } catch (e) {
        this.setReport({
          type: 'error',
          message: (e as Error).message,
          value: true,
        });
      }
    }

    await this.reloadMembers();

    // clear the autocomplete
    this.model = null;
    this.items = [];
    this.search = '';
  }

  checkIfUserExists(email: string): boolean {
    return this.members?.some((member: any) => member?.email === email) ?? false;
  }

  @Watch('search')
  async fetchUsers(val?: string) {
    if (this.loading || val == null) return;
    if (!this.isEmailValid(val)) {
      this.items = [];
      return;
    }
    this.loading = true;
    try {
      this.items = await UserService.fetchUserByEmail(val);
    } catch (e) {
      this.setReport({ type: 'error', message: e.message, value: true });
    } finally {
      this.loading = false;
    }
  }

  // clear items @blur, otherwise it will still show
  // the old result when focusing the autocomplete
  clearItems() {
    this.items = [];
    this.search = '';
  }

  /**
   * Validates email
   * @param {string} s email
   */
  isEmailValid(s: string): boolean {
    // https://stackoverflow.com/a/574698
    if (s.length < 3 || s.length > 254) return false;

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(s.toLowerCase());
  }
  // end User Search

  // Table
  members: {id: string; role: number; last_training?: string}[] | IPartnerMember[] | undefined = [];
  partnerObject: IPartner | null = null;

  get availableMemberRoles() {
    return [
      { text: 'Admin', value: UserRoleCode.admin },
      { text: 'User', value: UserRoleCode.user },
    ];
  }

  get tableHeaders() {
    return [
      {
        text: this.$t('uiComponents.settings.permissions.table.name'),
        value: 'name',
        width: '30%',
        sortable: false,
      },
      {
        text: this.$t('uiComponents.settings.permissions.table.email'),
        value: 'email',
        width: '20%',
        sortable: false,
      },
      {
        text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.headers.lastTraining'),
        value: 'last_training',
        sortable: false,
        width: '20%',
      },
      {
        text: this.$t('uiComponents.settings.permissions.table.userRole'),
        value: 'role',
        width: '20%',
        sortable: false,
      },
      {
        text: this.$t('uiComponents.settings.permissions.table.actions'),
        value: 'actions',
        width: '10%',
        sortable: false,
      },
    ];
  }

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  async handleChange(user: IPartnerMember, event: any, type: string) {
    const usersArray = (this.partnerObject?.users as IPartnerMember[]).map((member, index) => {
      if (member.id === user.id) {
        const updatedUser: any = { id: member.id, role: user.role, last_training: member.last_training };
        if (type === 'last_training') {
          // update the last training date
          updatedUser.last_training = event;
          if (this.members) {
            // update the last training date in the local array
            this.members[index].last_training = event;
          }
        }
        // if the user is the current user update the properties
        return updatedUser;
      }
      return {
        id: member.id,
        role: member.role,
        last_training: member.last_training ?? undefined,
      };
    }); // load the current users

    try {
      await this.handleUpdatePartner(usersArray);
      this.showUpdateMessage();
    } catch (e) {
      this.setReport({
        type: 'error',
        message: (e as Error).message,
        value: true,
      });
    }
  }

  /**
   * Deletes a member from the partner
   * @param {string} userId
   */
  async deleteMember(userId: string) {
    if (this.partnerObject) {
      let usersArray = (this.partnerObject?.users as IPartnerMember[]).map((member) => {
        return { id: member.id, role: member.role, last_training: member.last_training };
      });
      usersArray = usersArray.filter((member) => member.id !== userId);
      try {
        await this.handleUpdatePartner(usersArray);
        this.showUpdateMessage();
      } catch (e) {
        this.setReport({
          type: 'error',
          message: (e as Error).message,
          value: true,
        });
      }
    }

    await this.reloadMembers();
  }

  showUpdateMessage() {
    this.setReport({
      type: 'success',
      message: this.$t('uiComponents.reportMessages.updatePartner'),
      value: true,
    });
  }

  async handleUpdatePartner(usersArray: { id: string; role: number }[]) {
    await this.updatePartner({ id: this.partnerId, partner: { users: usersArray }, showMessage: false });
  }

  async reloadMembers() {
    if (this.partnerObject) {
      await this.fetchPartner({ id: this.partnerId });
      this.partnerObject = await this.getPartnerById(this.partnerId);
      if (this.partnerObject?.users) {
        this.members = this.partnerObject.users;
      }
    }
  }

  async created() {
    await this.fetchPartner({ id: this.partnerId });
    this.partnerObject = await this.getPartnerById(this.partnerId);
    if (this.partnerObject) {
      this.members = this.partnerObject.users;
    }
  }
}

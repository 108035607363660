
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { formatDate } from '@/utils/utilsFunctions';
import { Validation } from '@/ui/mixins/validation';
import { IPartner } from '@/types/partner.types';
import { ILead } from '@/types/leads.types';
import { Action, Getter } from 'vuex-class';
import { stateItems, nameByPartnerId } from '@/utils/partnerUtils';

@Component({
  components: {
    ModalWindow,
    InfoTooltip,
  },
  methods: {
    nameByPartnerId(id: string, partners: IPartner[]) {
      return nameByPartnerId(id, partners);
    },
  },
})
export default class LeadsInformation extends mixins(Validation) {
  @Prop({ default: false }) showAsPartnerList!: boolean;
  @Prop({ default: null }) leadsObject!: ILead;
  @Getter('partners/partners') partners!: IPartner[];
  @Action('leads/updateLead') updateLead!: (data: { id: string; lead: ILead; showMessage: boolean; skipFetch: boolean }) => Promise<void>;

  openState = false;
  lead: ILead = {
    id: '',
    partner_id: '',
    lead: '',
    address: '',
    phone_number: '',
    state: '',
    volume: 0,
    currency: '€',
    created_at: '',
    solarcalculator_id: '',
    notes: [],
  }
  shouldUpdateLead = false;
  currentMessage = '';

  get currentBreakpoint() {
    return this.$vuetify.breakpoint.name;
  }

  /**
   * Returns the keys to display in the modal
   */
  get keysToDisplay() {
    let keys = ['lead', 'created_at', 'address', 'phone_number', 'email', 'volume', 'partner', 'status'];
    if (this.showAsPartnerList) {
      // for the partner list, remove the partner key
      const itemsToRemove = ['partner'];
      keys = keys.filter((key) => !itemsToRemove.includes(key));
    }
    const breakpoint = ['lg', 'xl'];
    if (breakpoint.includes(this.currentBreakpoint)) {
      // for large screens, remove the partner and status keys
      const itemsToRemove = ['partner', 'status'];
      keys = keys.filter((key) => !itemsToRemove.includes(key));
    }
    return keys;
  }

  /*
    * Returns the partner items for the partner select
    */
  get partnerItems() {
    const mappings = this.partners.map((partner) => {
      return { value: partner.id, text: partner.data.name };
    });
    return mappings;
  }

  get localStateItems() {
    const items = stateItems();
    return items;
  }

  get currentLang() {
    return this.$i18n.locale;
  }

  handleDeleteReport(index: number) {
    if (!this.lead.notes) return;
    this.lead.notes.splice(index, 1);
    this.shouldUpdateLead = true;

    // force reactivity
    this.lead = { ...this.lead };
  }

  getFormatedDate(date: string) {
    let currentDate: Date | string = new Date(date);
    currentDate = formatDate(currentDate);
    return currentDate;
  }

  addReport() {
    // if notes are undefined, create an empty array
    if (this.lead.notes === undefined) {
      this.lead.notes = [];
    }
    this.lead.notes.push({
      text: this.currentMessage,
      created_at: new Date(),
    });
    this.currentMessage = '';
    this.shouldUpdateLead = true;
  }

  async onDialogStatusChange(status: boolean) {
    if (status) {
      this.lead = { ...this.leadsObject };
      if (!this.lead.notes) {
        this.lead.notes = [];
      }
      await this.$nextTick();
    } else {
      if (this.shouldUpdateLead) {
        this.updateLead({ id: this.lead.id, lead: this.lead, showMessage: true, skipFetch: true });
      }
      this.shouldUpdateLead = false;
    }
    this.openState = status;
  }
}


import { IAppState } from '@/store/modules/app/types';
import { Vue, Component } from 'vue-property-decorator';
import PartnerList from '@/ui/components/partner/management/lists/PartnerList.vue';
import LeadsList from '@/ui/components/partner/management/lists/LeadsList.vue';
import { Action, State } from 'vuex-class';
import { IPartner } from '@/types/partner.types';

@Component({
  components: {
    PartnerList,
    LeadsList,
  },
})
export default class PartnerManagement extends Vue {
  @State('app') appState!: IAppState;
  @Action('partners/fetchPartners') fetchPartners!: () => IPartner[];
  @Action('leads/fetchLeads') fetchLeads!: () => void;

  currentComponent = '';

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  get navigationItems() {
    const defaultList = [
      {
        title: 'Fachpartner Liste',
        locale: 'partners.partnerManagement.navigation.PartnerList',
        component: 'PartnerList',
        superAdmin: false,
      },
      {
        title: 'Leads',
        locale: 'partners.partnerManagement.navigation.LeadsList',
        component: 'LeadsList',
        superAdmin: true,
      },
    ];

    if (this.superAdmin) {
      return defaultList;
    } else {
      return defaultList.filter((item) => {
        return item.superAdmin === false;
      });
    }
  }

  async created() {
    await this.fetchPartners();
    await this.fetchLeads();
    // Set default component
    this.currentComponent = 'PartnerList';
  }
}
